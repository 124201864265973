@import '~antd/dist/antd.less';
@import '~antd/dist/antd.compact.less';

.margin-top-md {
  margin-top: 16px;
}
.display-flex {
  display: flex;
}
.justifyp-center {
  justify-content: center;
}
.justifyp-between {
  justify-content: space-between;
}
.text-align-right {
  text-align: right;
}
.lazyLoading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.verticalCenter {
  display: flex;
  align-items: center;
}

@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}
.text-align-left {
  text-align: left;
}

.page-breadcrumb {
  margin-bottom: 20px;
}

.margin-bottom-md {
  margin-bottom: @padding-md;
}
.margin-bottom-lg {
  margin-bottom: @padding-lg;
}
.margin-right-md {
  margin-right: @padding-md;
}
.margin-top-md {
  margin-top: @padding-md;
}
.margin-top-sm {
  margin-top: @padding-sm;
}
.padding-bottom-md {
  padding-bottom: @padding-md;
}
.padding-bottom-lg {
  padding-bottom: @padding-lg;
}
.padding-right-md {
  padding-right: @padding-md;
}
.padding-top-md {
  padding-top: @padding-md;
}
.padding-top-sm {
  margin-top: @padding-sm;
}

.FF6666 {
  color: #ff6666;
}
.color-85939E {
  color: #85939e;
}
.color-1879ff {
  color: #1879ff;
}

.display-flex {
  display: flex;
}
.flex-between {
  justify-content: space-between;
}
.flex-around {
  justify-content: space-around;
}
.flex-center {
  justify-content: center;
}
.flex-end {
  justify-content: end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: start;
}

.tips_info,
.tips_err {
  font-size: 14px;
  line-height: 22px;

  .anticon {
    margin-right: 5px;
  }
}
.tips_err {
  color: #ff6666;
}
.tips_info {
  color: rgba(0, 0, 0, 0.45);
  .anticon {
    color: #bfbfbf;
  }
}

.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.inline-block {
  display: inline-block;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #d4d4d4;
}

@primary-color: #1890FF;